import React, { useState } from 'react';
import ScorecardTable from './ScorecardTable';
import ScorecardSGAView from './ScorecardSGAView';
import './Scorecard.css';

const Scorecard = () => {
  const userRole = localStorage.getItem("userRole")?.toUpperCase();
  const allowedRoles = ["MGA", "RGA", "SGA"];
  const showTabs = allowedRoles.includes(userRole);
  const [view, setView] = useState('mga'); // Default to MGA view
  
  return (
    <div className="scorecard">
      <h4 style={{ marginLeft: '15px' }}>Scorecard</h4>
      {showTabs && (
        <div className="tabs">
          <input
            type="radio"
            id="mga"
            name="view_type"
            value="mga"
            checked={view === 'mga'}
            onChange={() => setView('mga')}
          />
          <label htmlFor="mga">MGA</label>
          <input
            type="radio"
            id="breakdown"
            name="view_type"
            value="breakdown"
            checked={view === 'breakdown'}
            onChange={() => setView('breakdown')}
          />
          <label htmlFor="breakdown">Breakdown</label>
        </div>
      )}
      {showTabs ? (
        view === 'mga' ? <ScorecardTable /> : <ScorecardSGAView />
      ) : (
        <ScorecardTable />
      )}
    </div>
  );
  
};

export default Scorecard;
