import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container } from 'react-bootstrap';
import logo from '../../img/globe1.png'; // Ensure this path is correct
import 'bootstrap/dist/css/bootstrap.min.css';
import { trackEvent } from '../utils/analytics'; 

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Tracks loading state
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    trackEvent('login_interaction', { category: 'Login', action: 'Username Field Interaction' });
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    trackEvent('login_interaction', { category: 'Login', action: 'Password Field Interaction' });
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Enable loading overlay
    trackEvent('login_attempt', { category: 'Login', action: 'Login Button Click' });
    const defaultUsernames = ['agent', 'mga', 'big4']; // Example default usernames
    if (defaultUsernames.includes(username)) {
      trackEvent('default_login_used', {
        category: 'Login',
        action: 'Default Login Attempt',
        label: username, // Track which default username was attempted
      });
    }
    // Simulate API call
    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/adminlogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
    .then(response => response.json())
    .then(data => {
      setIsLoading(false); // Disable loading overlay
      if (data.token) {
        // Save token and additional user info to localStorage
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userRole', data.user.role); // Save role (Admin level)
        localStorage.setItem('userEmail', data.user.email); // Save email
        localStorage.setItem('screenName', data.user.sceenName); // Save screen name
        localStorage.setItem('agency', data.user.agency); // Save agency
        localStorage.setItem('userId', data.user.id); // Save user ID
        localStorage.setItem('teamRole', data.user.teamRole); // Save agency name

        // Navigate to the admin verification page
        navigate('/admin/verify');
      } else {
        alert('Login failed'); // Handle login failure
      }
    })
    .catch(error => {
      setIsLoading(false); // Disable loading overlay
      alert('Login failed: ' + error.message);
    });
  };
  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <img src={logo} alt="Loading" className="loading-animation" />
        </div>
      )}

      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <Card className="w-100" style={{ maxWidth: "400px" }}>
            <Card.Header className="text-center" style={{backgroundColor: "white" }}>
                <img src={logo} alt="Logo" width="100" height="100" />
            </Card.Header>
          <Card.Body>
            <h2 className="text-center mb-4">Admin Login</h2>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label>Username</label>
                <input type="text" className="form-control" required value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <input type="password" className="form-control" required value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <Button className="w-100 mt-4" style={{backgroundColor: "green"}} type="submit">Log In</Button>
            </form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default LoginPage;
