import React, { useState, useEffect } from 'react';
import { Card, Spinner, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMedal, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MyTrophyCase.css';
import bronzeMedalImage from '../../img/bronzecoin.png';
import silverMedalImage from '../../img/silvercoin.png';
import goldMedalImage from '../../img/goldcoin.png';
import platinumMedalImage from '../../img/platinumcoin.png';
import diamondMedalImage from '../../img/diamondcoin.png';

const MyTrophyCase = ({ view }) => {
    const [trophyCaseData, setTrophyCaseData] = useState([]);
    const [allTrophyData, setAllTrophyData] = useState([]);
    const [recordWeekData, setRecordWeekData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [expandedClubs, setExpandedClubs] = useState({
        Bronze: true,
        Silver: true,
        Gold: true,
        Platinum: true,
        Diamond: true
    });

    useEffect(() => {
        const agnName = localStorage.getItem('agnName');
        if (agnName) {
            const fetchTrophyCaseData = async () => {
                try {
                    const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/trophy-case', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ agnName }),
                    });
                    const result = await response.json();
                    if (result.success && result.data.length > 0) {
                        setTrophyCaseData(result.data);
                    } else {
                        console.log(`No data found for agent: ${agnName}`);
                    }
                } catch (error) {
                    console.error('Error fetching trophy case data:', error);
                }
            };

            const fetchAllTrophyData = async () => {
                try {
                    const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-trophy-case');
                    const result = await response.json();
                    if (result.success) {
                        setAllTrophyData(result.data);
                    }
                } catch (error) {
                    console.error('Error fetching all trophy case data:', error);
                }
            };

            const fetchRecordWeekData = async () => {
                try {
                    const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/weekly-trophy-case', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ agnName }),
                    });
                    const result = await response.json();
                    if (result.success) {
                        const highestWeek = result.data.reduce((max, row) => {
                            return parseFloat(String(row.LVL_1_NET).replace(/[\$,()]/g, '').trim()) > parseFloat(String(max.LVL_1_NET).replace(/[\$,()]/g, '').trim()) ? row : max;
                        }, result.data[0]);
                        setRecordWeekData(highestWeek);
                    }
                } catch (error) {
                    console.error('Error fetching record week data:', error);
                }
            };

            fetchTrophyCaseData();
            fetchAllTrophyData();
            fetchRecordWeekData();
            setLoading(false);
        }
    }, []);

    const formatCurrency = (value) => {
        const numericValue = parseFloat(value.replace(/[\$,()]/g, '').trim());
        return numericValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const formatDateRange = (reportdate) => {
        const date = new Date(reportdate);
        const dayOfWeek = date.getDay();
        const lastSunday = new Date(date);
        lastSunday.setDate(date.getDate() - dayOfWeek);
        const previousMonday = new Date(lastSunday);
        previousMonday.setDate(lastSunday.getDate() - 6);
    
        const options = { month: 'numeric', day: 'numeric', year: '2-digit' };
        return `${previousMonday.toLocaleDateString('en-US', options)} - ${lastSunday.toLocaleDateString('en-US', options)}`;
    };
    
    const getClubName = (value) => {
        const numericValue = parseFloat(value.replace(/[\$,()]/g, '').trim());
        if (numericValue >= 100000) return 'Diamond';
        if (numericValue >= 50000) return 'Platinum';
        if (numericValue >= 25000) return 'Gold';
        if (numericValue >= 20000) return 'Silver';
        if (numericValue >= 15000) return 'Bronze';
        return 'Record Month';
    };

    const categorizeData = (data) => {
        const categories = {
            'Record Month': [],
            'Bronze': [],
            'Silver': [],
            'Gold': [],
            'Platinum': [],
            'Diamond': []
        };

        const highestRecordMonth = data.reduce((max, row) => {
            const netValue = parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim());
            return netValue > max.LVL_1_NET ? { ...row, LVL_1_NET: netValue } : max;
        }, { LVL_1_NET: 0 });

        categories['Record Month'].push(highestRecordMonth);

        data.forEach(row => {
            const clubName = getClubName(row.LVL_1_NET);
            if (clubName !== 'Record Month') {
                categories[clubName].push({ ...row, LVL_1_NET: parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim()) });
            }
        });

        return categories;
    };

    const categorizedData = categorizeData(trophyCaseData);

    const calculateRankings = (data) => {
        const rankings = {};

        const groupedByMonth = data.reduce((acc, row) => {
            const { month } = row;
            if (!acc[month]) acc[month] = [];
            acc[month].push({ ...row, LVL_1_NET: parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim()) });
            return acc;
        }, {});

        for (const [month, rows] of Object.entries(groupedByMonth)) {
            rows.sort((a, b) => b.LVL_1_NET - a.LVL_1_NET);
            rows.forEach((row, index) => {
                rankings[row.LagnName + month] = index + 1;
            });
        }

        return rankings;
    };

    const rankings = calculateRankings(allTrophyData);

    const toggleClubExpansion = (club) => {
        setExpandedClubs((prevState) => ({
            ...prevState,
            [club]: !prevState[club]
        }));
    };

    return (
        <>
            <div className="my-trophy-header">
                <h5>Trophy Case</h5>
                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" onClick={() => setShowInfoModal(true)} />
            </div>
            <div className="my-trophy-case-container">
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <div className="my-trophy-card-container">
                            {categorizedData['Record Month'].map((row, index) => (
                                <Card key={`record-${index}`} className="record-trophy my-trophy-card">
                                    <Card.Body className="my-trophy-card-body">
                                        <Card.Title className="my-trophy-card-title">Record Month</Card.Title>
                                        <div className="my-trophy-lvl1net">{formatCurrency(row.LVL_1_NET.toString())}</div>
                                        <div>{row.month}</div>
                                    </Card.Body>
                                </Card>
                            ))}
                            {recordWeekData && (
                                <Card className="record-week-trophy my-trophy-card">
                                    <Card.Body className="my-trophy-card-body">
                                        <Card.Title className="my-trophy-card-title">Record Week</Card.Title>
                                        <div className="my-trophy-lvl1net">{formatCurrency(recordWeekData.LVL_1_NET.toString())}</div>
                                        <div>{formatDateRange(recordWeekData.reportdate)}</div>
                                    </Card.Body>
                                </Card>
                            )}
                        </div>
                        {['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'].map((club) => (
                            categorizedData[club].length > 0 && (
                                <div key={club} className="club-section">
                                    <div className="my-club-title-container" onClick={() => toggleClubExpansion(club)} style={{ cursor: 'pointer' }}>
                                        <h5 className="my-club-title">
                                            {club} Club
                                        </h5>
                                        <div className="my-club-icons">
                                            {categorizedData[club].map((_, idx) => (
                                                <FontAwesomeIcon key={idx} icon={faMedal} className="my-club-icon" />
                                                ))}
                                        </div>
                                        <div style={{marginLeft: '5px'}}>
                                                <FontAwesomeIcon
                                                    icon={expandedClubs[club] ? faChevronDown : faChevronRight}
                                                    className="chevron-icon"
                                                />
                                                </div>
                                    </div>
                                    {expandedClubs[club] && (
                                        <div className="my-trophy-card-container">
                                            {categorizedData[club].map((row, idx) => (
                                                <Card key={`${club}-${idx}`} className={`${club.toLowerCase()}-trophy my-trophy-card`}>
                                                    <Card.Body className="my-trophy-card-body">
                                                        <div className="my-trophy-lvl1net">{formatCurrency(row.LVL_1_NET.toString())}</div>
                                                        <div>{row.month}</div>
                                                        {rankings[row.LagnName + row.month] <= 3 && (
                                                            <div>Ranked #{rankings[row.LagnName + row.month]} in the organization</div>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                    </>
                )}
            </div>

            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Trophy Case Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-content">
                    <p>Your Trophy Case is an easy way to keep track of your production records achieved in your career. Being a part of one of these clubs is a display of your commitment to your own success and a reminder of the effort it took to attain this accolade. Below are the <strong>Net ALP</strong> levels needed in a month needed to reach each club level:</p>
                    <ul>
                        <li>
                            <img src={bronzeMedalImage} alt="Bronze Medal" />
                            Bronze Club: $15,000 - $19,999
                        </li>
                        <li>
                            <img src={silverMedalImage} alt="Silver Medal" />
                            Silver Club: $20,000 - $24,999
                        </li>
                        <li>
                            <img src={goldMedalImage} alt="Gold Medal" />
                            Gold Club: $25,000 - $49,999
                        </li>
                        <li>
                            <img src={platinumMedalImage} alt="Platinum Medal" />
                            Platinum Club: $50,000 - $99,999
                        </li>
                        <li>
                            <img src={diamondMedalImage} alt="Diamond Medal" />
                            Diamond Club: $100,000 and above
                        </li>
                    </ul>
                    <p>If you have a record week or month that is not currently showing, please have your MGA send proof to kvanbibber@ariasagencies.com using either a P&P or week certificate from the Home Office.</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MyTrophyCase;
