import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Placeholder from "../utils/Placeholder";
import { FaTrash, FaRegCopy } from "react-icons/fa"; // Importing FontAwesome trash icon
import ReferralsComponent from "./RefComponent";
import { v4 as uuidv4 } from 'uuid';

const RefEntry = ({ initialData = [] }) => {
  const [tableData, setTableData] = useState(initialData);
  const [agentOptions, setAgentOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to current month
  const [monthOptions, setMonthOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const screenName = localStorage.getItem("screenName") || "Lead Team"; 
  const [selectedTab, setSelectedTab] = useState(screenName);
  const [adminNames, setAdminNames] = useState([]); // Store unique admin names
const [trueRefFilter, setTrueRefFilter] = useState("all"); // Default to "All"
const [sortColumn, setSortColumn] = useState("created_at");
const [sortDirection, setSortDirection] = useState("desc");
const [changedRows, setChangedRows] = useState(new Map());

const formatDateForDisplay = (date) => {
    if (!date) return "-"; // Return a dash if date is not set
    return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
};

// Function to handle sorting
const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(prevDirection =>
        prevDirection === "asc" ? "desc" : "asc"
    );
};

const sortedTableData = [...tableData].sort((a, b) => {
  if (!sortColumn) return 0;

  let valueA = a[sortColumn] || "";
  let valueB = b[sortColumn] || "";

  // Convert dates for sorting (Ensuring descending order for `created_at`)
  if (sortColumn === "created_at" || sortColumn === "date_app_checked") {
      valueA = valueA ? new Date(valueA) : new Date(0);
      valueB = valueB ? new Date(valueB) : new Date(0);
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA; // Descending order default
  }
  
  // Convert numeric values
  else if (!isNaN(valueA) && !isNaN(valueB)) {
      valueA = parseFloat(valueA);
      valueB = parseFloat(valueB);
  } 
  
  // String sorting
  else {
      valueA = valueA.toString().toLowerCase();
      valueB = valueB.toString().toLowerCase();
  }

  if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
  if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
  return 0;
});

useEffect(() => {
    const fetchAgents = async () => {
        console.time("Fetch Agents API"); // Start timer
        try {
            const response = await fetch("https://morning-sands-14889-102dd928c446.herokuapp.com/api/active-users");
            const data = await response.json();
            console.timeEnd("Fetch Agents API"); // End timer

            if (data.success && Array.isArray(data.data)) {
                console.time("Sorting Agents"); // Start sorting timer
                const sortedAgents = data.data.sort((a, b) => a.lagnname.localeCompare(b.lagnname));
                console.timeEnd("Sorting Agents"); // End sorting timer

                setAgentOptions(sortedAgents);
            }
        } catch (error) {
            console.error("Error fetching agent names:", error);
        }
    };

    fetchAgents();
}, []);


  useEffect(() => {
    const fetchMonthOptions = async () => {
        try {
            const response = await fetch("https://morning-sands-14889-102dd928c446.herokuapp.com/api/refvalidation/month-options");
            const data = await response.json();

            if (data.success) {
                setMonthOptions(data.data);
            }
        } catch (error) {
            console.error("Error fetching month options:", error);
        }
    };

    fetchMonthOptions();
}, []);

useEffect(() => {
  const fetchAdminTabs = async () => {
      try {
          const response = await fetch("https://morning-sands-14889-102dd928c446.herokuapp.com/api/refvalidation/admin-tabs");
          const data = await response.json();

          if (data.success) {
              let tabs = data.data;

              // Ensure the logged-in user's tab is included
              if (!tabs.includes(screenName)) {
                  tabs.push(screenName);
              }

              // Sort alphabetically but keep "Lead Team" first
              const sortedTabs = ["Lead Team", ...tabs.filter(tab => tab !== "Lead Team").sort()];

              setAdminNames(sortedTabs);

              // ✅ Ensure selectedTab is correctly set only if missing
              if (!sortedTabs.includes(selectedTab)) {
                  setSelectedTab(sortedTabs.includes(screenName) ? screenName : "Lead Team");
              }
          }
      } catch (error) {
          console.error("Error fetching admin tabs:", error);
      }
  };

  fetchAdminTabs();
}, [screenName]); // ✅ Re-run only if screenName changes

useEffect(() => {
  if (agentOptions.length === 0 || !selectedMonth || adminNames.length === 0) return; // ⬅️ Wait until adminNames is set

  const fetchReferrals = async () => {
    if (agentOptions.length === 0 || !selectedMonth || adminNames.length === 0) return;
  
    setIsLoading(true);
    console.time("Fetch Referrals API");
    try {
        const response = await fetch(
            `https://morning-sands-14889-102dd928c446.herokuapp.com/api/refvalidation/all?month=${selectedMonth}&admin_name=${selectedTab}&true_ref=${trueRefFilter}`
        );
  
        const data = await response.json();
        console.timeEnd("Fetch Referrals API");
  
        if (data.success) {
            console.time("Processing Referrals Data");
            const updatedData = data.data.map((row) => {
                const matchedAgent = agentOptions.find(agent => agent.lagnname === row.lagnname);
  
                return {
                    ...row,
                    agent_id: matchedAgent ? matchedAgent.id : row.agent_id || null,
                    agentName: matchedAgent ? matchedAgent.lagnname : row.agentName || "",
                    created_at: row.created_at ? row.created_at.slice(0, 7) : currentMonth,
                    date_app_checked: row.date_app_checked
                };
            });
            console.timeEnd("Processing Referrals Data");

                        // Count duplicates
                        const duplicateCounts = new Map();
                        updatedData.forEach(row => {
                            const key = `${row.lagnname}-${row.client_name}`;
                            duplicateCounts.set(key, (duplicateCounts.get(key) || 0) + 1);
                        });
            
                        // Log duplicates (only showing duplicates)
                        console.log("🟡 Duplicate Count Log:");
                        duplicateCounts.forEach((count, key) => {
                            if (count > 1) {
                                console.log(`❗ Duplicate: ${key} - Count: ${count}`);
                            }
                        });
            const uniqueData = Array.from(new Map(updatedData.map(row => [row.id, row])).values());

            setTableData(uniqueData);
        }
    } catch (error) {
        console.error("Error fetching referral data:", error);
    } finally {
        setIsLoading(false);
    }
  };
  

  fetchReferrals();
}, [agentOptions, selectedMonth, selectedTab, trueRefFilter, adminNames]); // ⬅️ Dependencies include `adminNames`


const handleEditRow = (rowId) => {
  setEditingRow(rowId); // Store row ID instead of index
};


const handleSaveRow = () => {
    handleSave();  // 🔥 This ensures data is sent to the backend
    setEditingRow(null);
};


const handleCellChange = (rowId, columnName, value) => {
    setTableData(prevData => {
        return prevData.map(row => {
            if (row.uuid === rowId) {
                // If value hasn't changed, return the original data
                if (row[columnName] === value) return row;

                const updatedRow = { ...row, [columnName]: value };

                // Keep "trial" logic unchanged
                if (columnName === "trial" && value === "Y") {
                    updatedRow.true_ref = "N";
                }

                // Track changed rows using UUID
                setChangedRows(prev => {
                    const newChanges = new Map(prev);
                    newChanges.set(rowId, updatedRow);
                    return newChanges;
                });

                return updatedRow;
            }
            return row;
        });
    });
};


const handleCancelEdit = () => {
    setTableData(prevData => [...prevData]); // Reset changes
    setEditingRow(null); // Exit edit mode
};

// Updated handleAddRow function with UUID assignment
const handleAddRow = () => {
  const screenName = localStorage.getItem("screenName") || "Unknown Admin";
  const userId = localStorage.getItem("userId") || null;
  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  const newRow = {
      uuid: uuidv4(), // Generate a unique UUID for the row
      id: null, 
      true_ref: "",
      ref_detail: "",
      agentName: "",
      agent_id: null,
      client_name: "",
      zip_code: "",
      existing_policy: "",
      trial: "",
      date_app_checked: today,  
      notes: "",
      created_at: currentMonth, 
      admin_name: screenName, 
      admin_id: userId,
  };

  console.log("Adding new row:", newRow);

  setTableData(prev => [newRow, ...prev]);

  // Update the adminNames list if this admin isn't already in the tabs
  setAdminNames(prevAdmins => {
      if (!prevAdmins.includes(screenName)) {
          return [...prevAdmins, screenName].sort();
      }
      return prevAdmins;
  });
};

const updateAdminTabs = (updatedData) => {
    const uniqueAdminNames = Array.from(new Set(updatedData.map(row => row.admin_name))).sort();

    // Ensure "Lead Team" is the last tab
    if (!uniqueAdminNames.includes("Lead Team")) {
        uniqueAdminNames.push("Lead Team");
    }

    setAdminNames(uniqueAdminNames);

    // Default to screenName if available, otherwise select the first admin, otherwise "Lead Team"
    const newSelectedTab = uniqueAdminNames.includes(screenName)
        ? screenName
        : uniqueAdminNames.length > 0
            ? uniqueAdminNames[0]
            : "Lead Team";

    setSelectedTab(newSelectedTab);
};

  
const canAddRow = () => {
    const prevMonth = new Date();
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    const prevMonthFormatted = prevMonth.toISOString().slice(0, 7);
    
    return selectedMonth === currentMonth || selectedMonth === prevMonthFormatted;
};

const handleDeleteRow = async (rowUuid) => {
  const rowToDelete = tableData.find(row => row.uuid === rowUuid || row.id === rowUuid);

  if (!rowToDelete) {
      console.error("❌ Row does not exist in tableData:", rowUuid);
      return;
  }

  const deleteId = rowToDelete.uuid || rowToDelete.id;
  console.log("🟡 Attempting to delete row with ID:", deleteId);

  if (!deleteId) {
      console.warn("⚠️ Row has no ID, removing from frontend only:", rowToDelete);
      const updatedTableData = tableData.filter(row => row.uuid !== rowUuid);
      setTableData(updatedTableData);
      updateAdminTabs(updatedTableData);
      return;
  }

  try {
      console.log("🔹 Sending DELETE request for ID:", deleteId);
      const response = await fetch(
          `https://morning-sands-14889-102dd928c446.herokuapp.com/api/refvalidation/delete/${deleteId}`,
          { method: "DELETE" }
      );

      const data = await response.json();
      console.log("🔹 DELETE response body:", data);

      if (data.success) {
          console.log(`✅ Row ${deleteId} deleted successfully from backend`);
          setTableData(prevData => prevData.filter(row => row.uuid !== rowUuid));
          updateAdminTabs(tableData);
      } else {
          console.error("❌ Failed to delete row from backend:", data.message);
      }
  } catch (error) {
      console.error("❌ Error deleting row:", error);
  }
};


const handleSave = async () => {
  if (changedRows.size === 0) {
      console.log("No changes to save.");
      return;
  }

  const updatedRows = Array.from(changedRows.values()).map(row => ({
      ...row,
      lagnname: row.agentName,
      admin_name: row.admin_name,
      admin_id: row.admin_id,
      date_app_checked: row.date_app_checked || null,
  }));

  console.log("Saving changed rows:", updatedRows);

  try {
      const response = await fetch("https://morning-sands-14889-102dd928c446.herokuapp.com/api/refvalidation/save", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedRows),
      });

      const data = await response.json();
      console.log("Server response:", data);

      if (data.success) {
          console.log("Rows saved successfully:", data);

          if (data.savedRows && Array.isArray(data.savedRows)) {
              setTableData(prevData => {
                  // Map saved rows by UUID to ensure the correct row is updated
                  const savedDataMap = new Map(data.savedRows.map(row => [row.uuid, row]));

                  return prevData.map(row => {
                      if (savedDataMap.has(row.uuid)) {
                          return { ...row, ...savedDataMap.get(row.uuid) }; // Merge updated data
                      }
                      return row;
                  });
              });

              // Remove saved rows from changedRows map
              data.savedRows.forEach(savedRow => {
                  setChangedRows(prev => {
                      const newChanges = new Map(prev);
                      newChanges.delete(savedRow.uuid);
                      return newChanges;
                  });
              });
          } else {
              console.warn("No savedRows returned from API. Skipping update.");
          }

          setChangedRows(new Map()); // Reset changedRows after saving
      } else {
          console.error("Failed to save rows:", data.message);
      }
  } catch (error) {
      console.error("Error saving rows:", error);
  }
};


  return (
    <div>
<div style={{ display: "flex", gap: "10px", marginTop: '10px', marginBottom: "10px", alignItems: "flex-start" }}>
    {/* True Ref Filter */}
    <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "5px", fontWeight: "bold", textAlign: "left" }}>Filter by True Ref:</label>
        <Select
            options={[
                { value: "all", label: "All" },
                { value: "blank", label: "Blank" },
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
            ]}
            value={{ value: trueRefFilter, label: trueRefFilter === "all" ? "All" : trueRefFilter.toUpperCase() }}
            onChange={(selectedOption) => setTrueRefFilter(selectedOption.value)}
            placeholder="Select..."
            styles={{
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999, // Ensure the dropdown is on top
                }),
                control: (provided) => ({
                    ...provided,
                    minWidth: "180px", // Consistent width
                }),
            }}
        />
    </div>

    {/* Month Filter */}
    <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "5px", fontWeight: "bold", textAlign: "left" }}>View Month:</label>
        <Select
            options={monthOptions}
            value={{
                value: selectedMonth,
                label: new Date(`${selectedMonth}-01T00:00:00`).toLocaleDateString('default', { month: 'long', year: 'numeric' })
            }}
            onChange={(selectedOption) => setSelectedMonth(selectedOption ? selectedOption.value : currentMonth)}
            placeholder="Select a month..."
            styles={{
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999, // Ensure the dropdown is on top
                }),
                control: (provided) => ({
                    ...provided,
                    minWidth: "200px", // Consistent width
                }),
            }}
        />
    </div>

    {/* Search Bar */}
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <label style={{ marginBottom: "5px", fontWeight: "bold", textAlign: "left" }}>Search:</label>
        <input
            type="text"
            placeholder="Search table..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            style={{
                width: "100%",
                padding: "6px",
                borderRadius: "4px",
                border: "1px solid #ccc",
            }}
        />
    </div>
</div>

{editingRow === null && canAddRow() && (
    <button className="insured-button" onClick={handleAddRow} style={{ marginBottom: "10px" }}>
        Add Row
    </button>
)}

{editingRow !== null && (
    <div style={{ display: "flex", gap: "10px", marginTop: "10px", marginBottom: "10px" }}>
        <button className="insured-button" onClick={handleSaveRow}>
            Save Changes
        </button>
        <button className="insured-button cancel-button" onClick={handleCancelEdit}>
            Cancel
        </button>
    </div>
)}

{/* Tab Navigation */}
<div style={{ display: "flex", gap: "10px", marginBottom: "15px", borderBottom: "2px solid #ccc" }}>
    {adminNames.map(admin => (
        <button
            key={admin}
            onClick={() => setSelectedTab(admin)}
            style={{
                padding: "5px 10px",
                border: "none",
                backgroundColor: selectedTab === admin ? "rgb(0, 85, 140)" : "#ddd",
                color: selectedTab === admin ? "#fff" : "#000",
                cursor: "pointer",
                borderRadius: "5px 5px 0 0",
                fontSize: "12px",
            }}
        >
            {admin}
        </button>
    ))}
</div>

{isLoading ? (
    <Placeholder />
) : (
<div style={{ 
    minHeight: "675px", // Adjust height as needed
    maxHeight: "675px", // Adjust height as needed
    overflowY: "auto",
    overflowX: "auto",
    border: "1px solid #ccc", // Optional border for visibility
    display: "block"
}}
>        <table className="agentcount-table">
<thead style={{ 
    position: "sticky", 
    top: 0, 
    backgroundColor: "rgb(0, 85, 140)", 
    zIndex: 10,
    cursor: "pointer"
}}>  
<tr>
    <th style={{ width: "50px", backgroundColor: "rgb(0, 85, 140)", color: "#fff" }}></th>

    {selectedTab === "Lead Team" && (
        <>
            <th style={{ backgroundColor: "rgb(0, 85, 140)", color: "#fff" }} onClick={() => handleSort("true_ref")}>
                True Ref {sortColumn === "true_ref" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
            </th>
            <th style={{ minWidth: "30ch", backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("ref_detail")}>
                Ref Detail {sortColumn === "ref_detail" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
            </th>
        </>
    )}

    <th style={{ minWidth: "40ch", backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("agentName")}>
        Agent Name {sortColumn === "agentName" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    <th style={{ minWidth: "35ch", backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("client_name")}>
        Client Name (Last, First) {sortColumn === "client_name" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    <th style={{ minWidth: "6ch", backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("zip_code")}>
        Zip Code {sortColumn === "zip_code" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    {selectedTab === "Lead Team" && (
    <th style={{ backgroundColor: "rgb(0, 85, 140)", color: "#fff" }}>
        
    </th>
)}

    <th style={{ backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("existing_policy")}>
        Existing Policy {sortColumn === "existing_policy" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    <th style={{ backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("trial")}>
        Trial {sortColumn === "trial" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    <th style={{ backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("date_app_checked")}>
        Date App Checked {sortColumn === "date_app_checked" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
    <th style={{ minWidth: "45ch", backgroundColor: "rgb(0, 85, 140)" }} onClick={() => handleSort("notes")}>
        Notes {sortColumn === "notes" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
    </th>
</tr>
</thead>


<tbody>
  {sortedTableData.length > 0 ? (
    sortedTableData
      .filter(row =>
        searchQuery === "" || 
        row.agentName?.toLowerCase().includes(searchQuery) ||
        row.client_name?.toLowerCase().includes(searchQuery) ||
        row.ref_detail?.toLowerCase().includes(searchQuery) ||
        row.notes?.toLowerCase().includes(searchQuery)
      )
      .map(row => {
        // Determine row background color
        let backgroundColor = "transparent";
        if (editingRow === row.uuid) {
          backgroundColor = "#FFE5B4"; // Pastel Orange for editing row
        } else if (row.true_ref === "Y") {
          backgroundColor = "#D9EAD3"; // Light Green for True Ref = Y
        } else if (row.true_ref === "N") {
          backgroundColor = "#F4CCCC"; // Light Red for True Ref = N
        }

        return (
          <tr
            key={row.uuid || row.id}
            onClick={() => handleEditRow(row.uuid)} // ✅ Now tracking by UUID
            style={{ backgroundColor }}
          >
            {/* Delete Button */}
            <td>
              <button 
                onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering row edit
                  const confirmDelete = window.confirm("Are you sure you want to delete this row?");
                  if (confirmDelete) {
                    handleDeleteRow(row.uuid); // ✅ Now using UUID instead of rowIndex
                  }
                }}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ccc",
                  fontSize: ".95rem",
                  transition: "color 0.2s ease-in-out",
                }}
                onMouseEnter={(e) => e.target.style.color = "#888"} // Darker gray on hover
                onMouseLeave={(e) => e.target.style.color = "#ccc"} // Back to light gray
                title="Delete Row"
              >
                <FaTrash />
              </button>
            </td>

            {/* True Ref and Ref Detail (Only in "Lead Team" Tab) */}
            {selectedTab === "Lead Team" && (
              <>
                <td>
                  {editingRow === row.uuid ? (
                    <select
                      value={row.true_ref}
                      onChange={(e) => handleCellChange(row.uuid, "true_ref", e.target.value)}
                    >
                      <option value=""> </option>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  ) : (
                    row.true_ref || "-"
                  )}
                </td>

                <td>
                  {editingRow === row.uuid ? (
                    <input
                      type="text"
                      value={row.ref_detail || ""}
                      onChange={(e) => handleCellChange(row.uuid, "ref_detail", e.target.value)}
                    />
                  ) : (
                    row.ref_detail || "-"
                  )}
                </td>
              </>
            )}

            {/* Agent Name */}
            <td>
              {editingRow === row.uuid ? (
                <Select
                  options={agentOptions.map(agent => ({
                    value: agent.id,
                    label: agent.lagnname,
                  }))}
                  value={row.agent_id ? { value: row.agent_id, label: row.agentName } : null}
                  onChange={(selectedOption) => {
                    handleCellChange(row.uuid, "agent_id", selectedOption?.value || null);
                    handleCellChange(row.uuid, "agentName", selectedOption?.label || "");
                  }}
                  isClearable
                  isSearchable
                />
              ) : (
                row.agentName || "-"
              )}
            </td>

            {/* Client Name */}
            <td>
              {editingRow === row.uuid ? (
                <input
                  type="text"
                  value={row.client_name || ""}
                  onChange={(e) => handleCellChange(row.uuid, "client_name", e.target.value)}
                />
              ) : (
                row.client_name || "-"
              )}
            </td>

            {/* Zip Code */}
            <td>
              {editingRow === row.uuid ? (
                <input
                  type="text"
                  maxLength="5"
                  value={row.zip_code || ""}
                  onChange={(e) => handleCellChange(row.uuid, "zip_code", e.target.value)}
                />
              ) : (
                row.zip_code || "-"
              )}
            </td>

            {/* Copy Button Column (Only for Lead Team) */}
            {selectedTab === "Lead Team" && (
              <td style={{ textAlign: "center" }}>
                <FaRegCopy
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering row edit
                    const firstName = row.client_name ? row.client_name.split(",")[0].trim() : "";
                    const copyText = `${firstName} ${row.zip_code || ""}`;
                    navigator.clipboard.writeText(copyText)
                      .then(() => console.log(`Copied: ${copyText}`))
                      .catch(err => console.error("Failed to copy text:", err));
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#666",
                    transition: "color 0.2s ease-in-out",
                  }}
                  onMouseEnter={(e) => e.target.style.color = "#00558C"}
                  onMouseLeave={(e) => e.target.style.color = "#666"}
                  title="Copy First Name & Zip"
                />
              </td>
            )}

            {/* Existing Policy */}
            <td>
              {editingRow === row.uuid ? (
                <select
                  value={row.existing_policy}
                  onChange={(e) => handleCellChange(row.uuid, "existing_policy", e.target.value)}
                >
                  <option value=""> </option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              ) : (
                row.existing_policy || "-"
              )}
            </td>

            {/* Trial */}
            <td>
              {editingRow === row.uuid ? (
                <select
                  value={row.trial}
                  onChange={(e) => handleCellChange(row.uuid, "trial", e.target.value)}
                >
                  <option value=""> </option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              ) : (
                row.trial || "-"
              )}
            </td>

            {/* Date App Checked */}
            <td>
              {formatDateForDisplay(row.date_app_checked)}
            </td>

            {/* Notes */}
            <td>
              {editingRow === row.uuid ? (
                <input
                  type="text"
                  value={row.notes || ""}
                  onChange={(e) => handleCellChange(row.uuid, "notes", e.target.value)}
                />
              ) : (
                row.notes || "-"
              )}
            </td>
          </tr>
        );
      })
  ) : (
    <tr>
      <td colSpan={selectedTab === "Lead Team" ? 10 : 8} style={{ textAlign: "center" }}>
        No data available. Click "Add Row" to enter data.
      </td>
    </tr>
  )}
</tbody>


        </table>
      </div>
)}

    </div>
  );
};

export default RefEntry;
