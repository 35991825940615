import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';
import moment from 'moment';

const AdminAlpSpreadsheet = () => {
  const [data, setData] = useState([]);
  const [selectedRGA, setSelectedRGA] = useState('All'); // New state for RGA filter
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch only the hierarchy data – no reported activity is needed now
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://ariaslogin-4a95935f6093.herokuapp.com/api/allHierarchy'
        );
        if (response.data.success) {
          console.log(response.data.data);
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Compute unique RGAs for the select dropdown
  const uniqueRGAs = Array.from(
    new Set(data.map((item) => item.RGA).filter((rga) => rga))
  ).sort((a, b) => a.localeCompare(b));

  const filteredData = data.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    const matchesSearch =
      (item.RGA && item.RGA.toLowerCase().includes(lowerSearchTerm)) ||
      (item.MGA && item.MGA.toLowerCase().includes(lowerSearchTerm)) ||
      (item.AGTs && item.AGTs.toLowerCase().includes(lowerSearchTerm));
  
    // Standardize "No RGA" to "Agency" for filtering
    const itemRGA = item.RGA ? item.RGA : "Agency";
    const matchesRGA =
      selectedRGA === 'All' || selectedRGA === itemRGA;
  
    return matchesSearch && matchesRGA;
  });
  
  
  const groupedData = filteredData.reduce((acc, item) => {
    const rgaKey = item.RGA ? item.RGA : "Agency"; // Standardize "No RGA" to "Agency"
    if (!acc[rgaKey]) {
      acc[rgaKey] = [];
    }
    acc[rgaKey].push(item);
    return acc;
  }, {});
  
  

  const renderRGASections = () => {
    const sortedRGAs = Object.keys(groupedData).sort((a, b) => {
      if (a === 'No RGA') return 1; // Move "No RGA" to the end
      if (b === 'No RGA') return -1;
      return a.localeCompare(b);
    });
  
    let tablesGenerated = 0;
  
    const sections = sortedRGAs.map((rga) => {
      if (!groupedData[rga]) return null;
  
      // Count MGA tables actually created
      tablesGenerated += groupedData[rga].length;
  
      const mgaList = groupedData[rga].map((mga) => mga.MGA);
  
      if (rga === 'No RGA') {
        console.log(`Creating table for Agency with ${groupedData[rga].length} MGAs:`, mgaList);
        return <MGAWithAGTs key="agency" rga="Agency" mgas={groupedData[rga]} />;
      }
  
      console.log(`Creating table for RGA: ${rga} with ${groupedData[rga].length} MGAs:`, mgaList);
      return <MGAWithAGTs key={rga} rga={rga} mgas={groupedData[rga]} />;
    });
  
    console.log(`Total MGA tables generated: ${tablesGenerated}`);
  
    return sections;
  };
  
  
  
  return (
    <div>
<div style={{ textAlign: 'center', marginBottom: '20px' }}>
  <label htmlFor="rgaSelect">Filter by RGA: </label>
  <select
    id="rgaSelect"
    value={selectedRGA}
    onChange={(e) => setSelectedRGA(e.target.value)}
  >
    <option value="All">All</option>
    {uniqueRGAs
      .filter((rga) => rga !== "No RGA") // Remove "No RGA" to avoid duplicate Agency
      .map((rga) => (
        <option key={rga} value={rga}>
          {rga}
        </option>
      ))}
  </select>
</div>


      {/* Search Bar */}
      <div
        className="search-container"
        style={{ margin: '20px 0', textAlign: 'center' }}
      >
        <input
          type="text"
          placeholder="Search by RGA, MGA or agent details..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '80%', padding: '8px', fontSize: '16px' }}
        />
      </div>

      {loading ? (
        <Placeholder />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div>{renderRGASections()}</div>
      )}
    </div>
  );
};

const reformatAgentName = (name) => {
  if (!name) return '';
  const parts = name.split(' ');
  if (parts.length < 2) return name;
  const [last, first, ...rest] = parts;
  return `${first} ${rest.join(' ')} ${last}`;
};

const MGAWithAGTs = ({ rga, mgas }) => {
  // Build a hierarchy for agents based on the AGT string in each MGA record
  const buildHierarchy = (data) => {
    const hierarchy = [];
    const map = {};

    // Initialize the map with each agent record
    data.forEach((item) => {
      map[item.lagnname] = { ...item, children: [] };
    });

    // Group children under their respective parent agents
    data.forEach((item) => {
      if (item.managerActive === 'n') return;
      if (item.sa && map[item.sa]) {
        map[item.sa].children.push(map[item.lagnname]);
      } else if (item.ga && map[item.ga]) {
        map[item.ga].children.push(map[item.lagnname]);
      } else if (item.mga && map[item.mga]) {
        map[item.mga].children.push(map[item.lagnname]);
      } else if (item.rga && map[item.rga]) {
        map[item.rga].children.push(map[item.lagnname]);
      } else {
        hierarchy.push(map[item.lagnname]);
      }
    });

    // Sort the hierarchy (adjust the order as needed)
    const sortHierarchy = (nodes) => {
      return nodes
        .sort((a, b) => {
          const order = ['RGA', 'MGA', 'GA', 'AGT', 'SA'];
          return order.indexOf(a.clname) - order.indexOf(b.clname);
        })
        .map((node) => {
          node.children = sortHierarchy(node.children);
          return node;
        });
    };

    return sortHierarchy(hierarchy);
  };

  const getColorForClname = (clname) => {
    switch (clname) {
      case 'RGA':
        return 'lightblue';
      case 'MGA':
        return '#68B675';
      case 'GA':
        return '#ED722F';
      case 'AGT':
        return 'black';
      case 'SA':
        return '#B25271';
      default:
        return 'black';
    }
  };

  const getFontWeightForClname = (clname) => {
    switch (clname) {
      case 'MGA':
      case 'GA':
      case 'SA':
        return 'bold';
      default:
        return 'normal';
    }
  };

  // Render each agent row without any reported activity columns
  const renderAgents = (agent) => {
    const clnameColor = getColorForClname(agent.clname);
    const clnameFontWeight = getFontWeightForClname(agent.clname);
    const formattedEsid = moment(agent.esid).format('MM/DD/YY');

    return (
      <React.Fragment key={agent.lagnname}>
        <tr id={`row-${agent.lagnname}`}>
          <td style={{ color: clnameColor, fontWeight: clnameFontWeight }}>
            {agent.additionalDetails}
          </td>
          <td className="first-column">{reformatAgentName(agent.lagnname)}</td>
          <td>{formattedEsid}</td>
          <td>{agent.email}</td>
        </tr>
        {agent.children &&
          agent.children.map((child) => renderAgents(child))}
      </React.Fragment>
    );
  };

  return (
    <div className="hier-table-container">
      {mgas.map((mga) => {
        // Split the AGTs string into individual agent details
        const parts = mga.AGTs.split('; ');
        const agents = parts
          .map((part, index) => {
            if (index === 0) {
              // First part holds MGA details
              const [lagnname, email, esid, clname] = part.split(' - ');
              return {
                lagnname,
                email,
                esid,
                clname: 'MGA',
                additionalDetails: 'MGA',
              };
            } else {
              // Subsequent parts are agent details
              return part.split('-- ').map((agt) => {
                const [lagnname, email, esid, sa, ga, mga, clname] =
                  agt.split(' - ');
                return {
                  lagnname,
                  email,
                  esid,
                  sa,
                  ga,
                  mga,
                  clname,
                  additionalDetails: clname,
                };
              });
            }
          })
          .flat();

        const sortedHierarchy = buildHierarchy(agents);

        return (
          <div key={mga.MGA}>
            <table className="hierarchyTable">
              <thead>
                {/* RGA header row */}
                <tr>
                  <th colSpan="4" className="rga-header">
                    {rga}
                  </th>
                </tr>
                {/* MGA header row */}
                <tr className="first-row">
                  <th className="day-header"></th>
                  <th className="day-header">
                    <h5 className="hier-mga-header">
                      {mga.MGA.split(' ')[0]}
                    </h5>
                  </th>
                  <th className="day-header">Hire Date</th>
                  <th className="day-header">Email</th>
                </tr>
              </thead>
              <tbody>{sortedHierarchy.map((agent) => renderAgents(agent))}</tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default AdminAlpSpreadsheet;
