import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const DiscordCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const userId = localStorage.getItem('userId'); // Ensure userId is retrieved

    if (code && userId) {
      // Send the code + userId to the backend
      axios
        .post('http://localhost:3000/api/auth/discord/callback', { code, userId })
        .then((response) => {
          console.log(response.data);
          navigate('/account'); // Ensure the redirect happens
        })
        .catch((error) => {
          console.error('Error linking Discord:', error);
          navigate('/account'); // Redirect to account page regardless of success/failure
        });
    } else {
      console.error("Missing code or userId");
      navigate('/account'); // Redirect if code/userId are missing
    }
  }, [location, navigate]);

  return <p>Linking Discord... Please wait.</p>;
};

export default DiscordCallback;
