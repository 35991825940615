import React from 'react';
import AdminAlpSpreadsheet from './AdminAlpSpreadsheet';
import HierarchyTable from './Hierarchy';

const AdminDashboard = () => {
    // Your code here

    return (
        <div>
            <HierarchyTable />
            </div>
    );
};

export default AdminDashboard;